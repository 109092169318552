import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'
import Image from '../components/Image/Image'
import Pricing from '../components/Pricing/Pricing'
import Accordion from '../components/Accordion/Accordion'

import Try from '../sections/Try/Try'

const AboutPage = () => {
  return (
    <Layout pageName="pricing">
      <Seo title="Pricing" />
      <Container>
        <section className="pricing">
          <Pricing />
        </section>

        <section className="policy">
          <h2 className="policy__heading heading heading--md">
            Fair Billing Policy
          </h2>
          <p className="policy__text">
            At Ally, you only get billed for the people who are visible in Ally
            each month. And if somebone you’ve already paid for becomes
            inactive, we’ll add a prorated credit to your account for the unused
            time. Because billing should be fair - and we like you.
          </p>
          <Image filename="pricing-policy" className="policy__image" />
        </section>

        <section className="for-good">
          <h2 className="heading heading--md">Ally For Good</h2>
          <p className="for-good__text">
            Ally wants to support people in the world who are doing good things.
            That’s why we offer special discount pricing for qualified nonprofit
            organizations and educational institutions.
          </p>
          <p className="for-good__link">
            <a href="/" className="link link--blue">
              Ally for nonprofites ->
            </a>
          </p>
          <p className="for-good__link">
            <a href="/" className="link link--blue">
              Ally for education ->
            </a>
          </p>
        </section>

        <section className="faq">
          <h2 className="heading heading--md hidden-mobile">
            Frequently Asked Questions
          </h2>
          <p className="visible-only-mobile">Commonly asked questions</p>

          <Accordion
            className="faq__accordion"
            items={[
              {
                question: `Who is active user?`,
                answer: `You can use your credit card to pay for any plan. If you sign up
              for an Annual subscription and plan to spend at least $2,000, we
              can invoice you annually. Visit the help center for more
              information or Contact us to start the process.`
              },
              {
                question: `What are my payment options - credit card and/or invoicing?`,
                answer: `You can use your credit card to pay for any plan. If you sign up
                for an Annual subscription and plan to spend at least $2,000, we
                can invoice you annually. Visit the help center for more
                information or Contact us to start the process.`
              },
              {
                question: `My team wants to cancel its subscription. How do we do that? Can
                we get a refund?`,
                answer: `You can use your credit card to pay for any plan. If you sign up
                for an Annual subscription and plan to spend at least $2,000, we
                can invoice you annually. Visit the help center for more
                information or Contact us to start the process.`
              }
            ]}
          />
        </section>

        <Try />
      </Container>
    </Layout>
  )
}

export default AboutPage
