import React, { Component } from 'react'
import {
  Accordion as AccordionBody,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

class Accordion extends Component {
  render() {
    return (
      <AccordionBody
        className={`accordion ${this.props.className}`}
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
      >
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <p className="bold">Who is active user?</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="small">
              You can use your credit card to pay for any plan. If you sign up
              for an Annual subscription and plan to spend at least $2,000, we
              can invoice you annually. Visit the help center for more
              information or Contact us to start the process.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <p className="bold">
                What are my payment options - credit card and/or invoicing?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="small">
              You can use your credit card to pay for any plan. If you sign up
              for an Annual subscription and plan to spend at least $2,000, we
              can invoice you annually. Visit the help center for more
              information or Contact us to start the process.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <p className="bold">
                My team wants to cancel its subscription. How do we do that? Can
                we get a refund?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="small">
              You can use your credit card to pay for any plan. If you sign up
              for an Annual subscription and plan to spend at least $2,000, we
              can invoice you annually. Visit the help center for more
              information or Contact us to start the process.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </AccordionBody>
    )
  }
}

export default Accordion
