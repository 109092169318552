import React, { Component } from 'react'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

const standardItems = [
  'Unlimited surveys',
  'Unlimited dashboards',
  'Unlimited alerts and notifications',
  'Unlimited scheduled reports',
  'Unlimited integrations',
  'Advanced permissions',
  'Priority support',
];

class Pricing extends Component {
  state = {
    categories: ['Ally for Teams', 'Ally for Enterprise'],
  }

  render() {
    const { categories } = this.state

    return (
      <Tabs className="pricing">
        <TabList>
          {categories.map((item, i) => (
            <Tab key={i}>{item}</Tab>
          ))}
        </TabList>

        <TabPanel>
          <div className="pricing__item">
            <h2 className="pricing__heading heading heading--md">
              Ally for teams of all sizes
            </h2>
            <p className="pricing__subheading">
            Straightforward, pay-as-you-go pricing
            </p>
            <div className="pricing__card">
              <h2 className="heading heading--md">Standard</h2>
              <p className="large bold">
                49 USD <br /> for teams of up to 10 users
              </p>
              <p className="extra-small">
                10 USD <br /> per active user, per month afterwards
              </p>
              <a href="https://app.geally.com" className="pricing__btn btn btn--blue">
                Try free
              </a>
              <ul className="pricing__list">
                {standardItems.map((item, i) => (<li key={i}>{item}</li>))}
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="pricing__item">
            <h2 className="pricing__heading heading heading--md">
              Ally For Enterpise{' '}
            </h2>
            <p className="pricing__subheading">
              Ally for Teams is a single place for your team visibility.
            </p>
            <div className="pricing__card">
              <h2 className="heading heading--md">Enterprise</h2>
              <p className="small bold">Starting from 999 USD per month</p>
              <p className="extra-small">
                Ally powers the usage and administration of multiple teams with
                On-Premises integrations
              </p>
              <a
                href="/"
                className="pricing__btn pricing__btn--top btn btn--blue"
                onClick={this.onContactUsClick}
              >
                Contact Us
              </a>
              <ul className="pricing__list">
                <li key="pricing-surveys">Unlimited surveys</li>
                <li>Unlimited dashboards</li>
                <li>Unlimited alerts and notifications</li>
                <li>Unlimited scheduled reports</li>
                <li>Unlimited and custom integrations</li>
                <li><strong>Enterprise Security and Compliance</strong>E (SOC 2 Type II)</li>
                <li><strong>Enterprise SLA</strong> and support</li>
                <li>Account Manager</li>
              </ul>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    )
  }

  onContactUsClick(e) {
    e.preventDefault();
    // eslint-disable-next-line
    Intercom('showNewMessage', 'Hi, I have a question about Ally.');
  }
}

export default Pricing
